import { Footer } from 'antd/lib/layout/layout';
import React from 'react';
import styled from 'styled-components';
import { appVersion } from '../../helpers/constant';
import useAuth from '../../hooks/useAuth';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';

interface Props {
  variant?: 'primary' | 'secondary';
  children?: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children, variant = 'primary' }) => {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const { oktaAuth } = useOktaAuth();

  const handleLogout = async () => {
    logout();
    await oktaAuth.signOut();
  };

  return (
    <CustomContainer>
      {variant === 'primary' ? (
        <div>
          <FormContainer>{children}</FormContainer>
        </div>
      ) : variant === 'secondary' ? (
        <div>
          <FormContainerApp>{children}</FormContainerApp>
        </div>
      ) : (
        <div>
          <FormContainer2>{children}</FormContainer2>
        </div>
      )}
      <Footer
        style={{
          textAlign: 'center',
          bottom: '5px',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
          color: 'rgb(255, 255, 255)',
          background: 'none',
          padding: 0,
          fontSize: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span>Vesion App {appVersion}</span>

        {pathname === '/select-application' && (
          <>
            <span style={{ marginLeft: 12, marginRight: 12 }}>|</span>
            <span
              onClick={handleLogout}
              style={{
                color: 'white',
                cursor: 'pointer',
              }}
            >
              Logout
            </span>
          </>
        )}
      </Footer>
    </CustomContainer>
  );
};

const CustomContainer = styled.div`
  background: url(/images/bg-auth.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
`;

const FormContainerApp = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  position: absolute;
  width: 700px;
  height: 700px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #e8ecf3;
  border-radius: 16px;
`;

const FormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 36px;
  position: absolute;
  width: 340px;
  height: 430px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #e8ecf3;
  border-radius: 16px;
`;

const FormContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  position: absolute;
  /* width: 800px;
  height: 800px;
  left: 28%;
  top: 6%; */

  background: #ffffff;
  border-radius: 8px;
`;

export default AuthLayout;
